<template>
  <CRow class="material border pl-1 pr-3 py-1 mx-0 full-repeater-row" :id="randomId">
    <CCol class="col-10">
      <CRow>
        <CCol class="mt-2" sm="2">
          <MaterialText v-model="localMaterial.stueckzahl" placeholder="Stück" form-label="Stück" />
        </CCol>
        <CCol class="mt-2" sm="3">
          <MaterialText rules="" v-model="localMaterial.artikelnr" placeholder="Artikel Nr." label="Art.-Nr." />
        </CCol>
        <CCol class="mt-2" sm="6">
          <MaterialText rules="" v-model="localMaterial.material" placeholder="Material" label="Material" />
        </CCol>
      </CRow>
    </CCol>
    <CCol class="col-2 mt-2 text-right m-auto center">
      <CButton
        color="danger"
        variant="outline"
        size="md"
        class="center m-auto center vertical-middle"
        v-c-tooltip="{content: 'Löschen', placement:'left'}"
        v-on:click="deleteEntry(randomId)"
      >
        <CIcon name="cil-trash"/>
      </CButton>
    </CCol>
  </CRow>
</template>

<script>
import MaterialText from '@/components/FormElements/Text'

export default {
  name: 'formular-material',
  components: {
    MaterialText
  },
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      randomId: 'repeater-row-material-' + this.getRandomInt(10000)
    }
  },
  computed: {
    localMaterial: {
      get () { return this.value },
      set (localMaterial) { this.$emit('input', localMaterial) }
    }
  },
  methods: {
    getRandomInt (max) {
      return Math.floor(Math.random() * max)
    },
    deleteEntry (entry) {
      console.log(entry)
      document.getElementById(entry).remove()
    }
  }
}
</script>

<style lang="scss">
.repeater-drop-zone:first-child button[title="Remove block"]{
  display: none !important;
}

.repeater-drop-zone button[content="Duplicate"]{
  display: none !important;
}
</style>
